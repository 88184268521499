import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import vueCustomElement from 'vue-custom-element'
import VueCookies from 'vue-cookies'

Vue.config.productionTip = false

Vue.use(vueCustomElement)
Vue.use(VueCookies, { expires: '4h', secure: true, sameSite: 'Strict'})

App.vuetify = vuetify
App.i18n = i18n

Vue.customElement('alert-banner-widget', App)

// new Vue({
//   render: h => h(App),
// }).$mount('#app')
